import { BREAKPOINT_NUMBER } from "./breakpoints";

const generateSrcSet = ({
    image,
    content,
    format,
    maxWidth,
    maxHeight,
    focalPoint,
}: generateSrcSetFromImageProps): SourceSet[] => {
    content = content.filter((item) => {
        return (
            (maxWidth && item.width ? item.width <= maxWidth : true) &&
            (maxHeight && item.height ? item.height <= maxHeight : true)
        );
    });

    return content.map((item) => {
        const hasBreakpoint = !!item.breakpoint;
        const formatString = format && format !== "webp" && `format=${format}`; // do not add format if it is webp
        const factor =
            !item.width || item.width < 1920
                ? 2
                : item.width < 2560
                  ? 1.5
                  : 1.25;
        const widthString = item.width && `width=${item.width}`;
        const width2xString = item.width && `width=${item.width * factor}`;
        const heightString = item.height && `height=${item.height}`;
        const height2xString = item.height && `height=${item.height * factor}`;

        const focalPointString =
            focalPoint &&
            item.width &&
            item.height &&
            `rxy=${focalPoint.replace("|", ",")}`; // Only set focalpoint if image has width and height
        const resizeModeString = !!item.width && !item.height && `rmode=min`; // Set resizemode to min if only width is set

        const shouldRenderBreakpoint1x =
            !hasBreakpoint ||
            (hasBreakpoint &&
                (!maxWidth || maxWidth >= (item.height || 0)) &&
                (!maxHeight || maxHeight >= (item.height || 0)));

        const shouldRenderBreakpoint2x =
            shouldRenderBreakpoint1x &&
            (!maxWidth || maxWidth >= (item.width || 0) * factor) &&
            (!maxHeight || maxHeight >= (item.height || 0) * factor);

        const firstDelimiter = image.includes("?") ? "&" : "?";

        const srcset1x = shouldRenderBreakpoint1x
            ? image +
              firstDelimiter +
              [
                  formatString,
                  "quality=80",
                  widthString,
                  heightString,
                  focalPointString,
                  resizeModeString,
              ]
                  .filter(Boolean)
                  .join("&")
            : "";

        const srcset2x = shouldRenderBreakpoint2x
            ? image +
              firstDelimiter +
              [
                  formatString,
                  "quality=80",
                  width2xString,
                  height2xString,
                  focalPointString,
                  resizeModeString,
              ]
                  .filter(Boolean)
                  .join("&") +
              " 2x"
            : "";

        const webPsrcset1x = shouldRenderBreakpoint1x
            ? image +
              firstDelimiter +
              [
                  "format=webp",
                  "quality=80",
                  widthString,
                  heightString,
                  focalPointString,
                  resizeModeString,
              ]
                  .filter(Boolean)
                  .join("&")
            : "";

        const webPsrcset2x = shouldRenderBreakpoint2x
            ? image +
              firstDelimiter +
              [
                  "format=webp",
                  "quality=80",
                  width2xString,
                  height2xString,
                  focalPointString,
                  resizeModeString,
              ]
                  .filter(Boolean)
                  .join("&") +
              " 2x"
            : "";

        const media = item.breakpoint
            ? `(min-width: ${item.breakpoint}px)`
            : undefined;
        return {
            media: media,
            srcset: [srcset1x, srcset2x].filter(Boolean).join(", "),
            src: srcset1x,
            webp: webPsrcset1x,
            webpsrcset: [webPsrcset1x, webPsrcset2x].filter(Boolean).join(", "),
            height: item.width,
            width: item.height,
        };
    });
};

export default generateSrcSet;

export const commonSourceSets = {
    large: [
        {
            width: 2560,
            breakpoint: BREAKPOINT_NUMBER.XXXL,
        },
        {
            width: 1920,
            breakpoint: BREAKPOINT_NUMBER.XXL,
        },
        {
            width: 1680,
            breakpoint: BREAKPOINT_NUMBER.XL,
        },
        {
            width: 1365,
            breakpoint: BREAKPOINT_NUMBER.L,
        },
        {
            width: 992,
            breakpoint: BREAKPOINT_NUMBER.M,
        },
        {
            width: 768,
            height: Math.ceil(768 / (2 / 3)),
        },
    ],
};

export const noCropLargeSourceSet = [
    ...commonSourceSets.large.filter(source => source.width !== 768),
    { width: 768 }
];

export interface generateSrcSetFromImageProps {
    image: string;
    content: { width?: number; height?: number; breakpoint?: number }[];
    format?: string;
    maxWidth?: number;
    maxHeight?: number;
    focalPoint?: string;
}

export interface SourceSet {
    media?: string;
    srcset: string;
    src: string;
    webp?: string;
    webpsrcset?: string;
    height?: number;
    width?: number;
}
