import React, {
    ReactElement,
    useCallback,
    useEffect,
    useRef,
    useState,
} from "react";
import { ThemeProvider } from "styled-components";

import CallToAction from "components/CallToAction";
import Image from "components/Image";
import Section from "components/Section";
import Watermark from "components/Watermark";
import { useIsInEditMode, useBreakpoint } from "hooks";
import { BodyL } from "style/components/Typography";
import generateSrcSet, { commonSourceSets } from "style/generateSrcSet";
import { addEditAttributes } from "utils/episerver";

import {
    Container,
    Heading,
    Introduction,
    IntroductionHtml,
    CallToActions,
    ReadMore,
    HeroMap,
    Logo,
    Col,
    HeroVideo,
    StyledButton,
    StyledForm,
    ReadMoreButton,
} from "./Hero.styled";
import HeroProps from "./HeroProps";

const Hero = ({
    identifier,
    heading,
    headingSize = "XXL",
    introductionHtml,
    introduction,
    image,
    ctas,
    video,
    backgroundImage,
    theme = "blue",
    contentTheme = "cyan",
    actionTheme = "cyan",
    showArrowdown = false,
    editAttributePrefix = "",
    watermark,
    link,
    map,
    l18n,
    logo,
    form,
    handleClick,
    handleKeyPress,
}: HeroProps): ReactElement => {
    const [isLoaded, setIsloaded] = useState(false);
    const heroImage = useRef<HTMLImageElement>(null);
    const [logoDimensions, setLogoDimensions] = useState({
        width: 0,
        height: 0,
    });
    if (image?.caption) delete image.caption;
    image = image || backgroundImage;

    const isInEditMode = useIsInEditMode();

    switch (theme) {
        case "black":
            actionTheme = "white";
            break;
    }

    const hasText = !!(heading || introduction);
    const hasImage = !!image;
    const hasMap = !!map;
    const hasVideo = !!video;

    const onLogoLoad = useCallback(
        (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
            const img = event.target as HTMLImageElement;
            setLogoDimensions({
                height: img.naturalHeight,
                width: img.naturalWidth,
            });
        },
        [],
    );

    useEffect(() => {
        if (
            heroImage.current?.complete &&
            (logoDimensions.width === 0 || logoDimensions.height === 0)
        )
            setLogoDimensions({
                height: heroImage.current.naturalHeight,
                width: heroImage.current.naturalWidth,
            });
    }, [heroImage, logoDimensions]);

    const getLogoFormat = (): string | undefined => {
        const { width, height } = logoDimensions;

        if (!width || !height || (width === 0 && height === 0)) {
            return undefined;
        }
        if (width > height) {
            return "landscape";
        }
        if (width < height) {
            return "portrait";
        }
        return "square";
    };

    const getVariant = () => {
        if (isInEditMode) return "edit";
        if (hasMap) return "map";
        if (hasVideo) return "video";
        if (hasImage || !!watermark) {
            if (hasText) return "full";
            return "image";
        }
        return "text";
    };

    const variant = getVariant();
    const hasCTA = ctas && ctas.length > 0;
    const hasColumn = hasCTA || !!link || !!logo || !!form;

    if (image && image.url) {
        image.srcSet = generateSrcSet({
            image: image.url,
            focalPoint: image.focalPoint,
            content: commonSourceSets.large,
            maxWidth: image.uploadedWidth,
            maxHeight: image.uploadedHeight,
            format: "jpg",
        });
    }

    useEffect(() => {
        if (!isLoaded) setIsloaded(true);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    let iconSize: string;
    switch (useBreakpoint(["XL"])) {
        case "XL":
            iconSize = "140";
            break;
        default:
            iconSize = "105";
    }

    return (
        <ThemeProvider
            theme={{
                theme: theme,
                variant: variant,
                hasCTA: hasCTA,
                hasColumn: hasColumn,
                format: getLogoFormat(),
            }}
        >
            <Section
                theme={theme}
                key={`heroContent`}
                alignItems="center"
                id={identifier}
            >
                {hasMap && isLoaded && (
                    <HeroMap
                        {...map}
                        options={{
                            disableDefaultUI: true,
                            zoomControl: true,
                        }}
                    />
                )}
                {hasVideo && <HeroVideo {...video} title={undefined} />}
                {!hasMap && image && (
                    <Image
                        {...image}
                        cover={true}
                        darken={hasText}
                        lazyload={false}
                        editPropertyName={`${editAttributePrefix}BackgroundImage`}
                    />
                )}
                {!hasMap && !hasVideo && watermark && (
                    <Watermark
                        key={`Watermark-${watermark}-${iconSize || ""}`}
                        icon={`${watermark}${iconSize}`}
                        variant={!image ? "color" : "white"}
                    />
                )}
                <Container $showArrowdown={showArrowdown}>
                    {!hasMap && !hasVideo && (
                        <>
                            {(isInEditMode || heading) && (
                                <Heading
                                    {...addEditAttributes(
                                        `${editAttributePrefix}Heading`,
                                    )}
                                    dangerouslySetInnerHTML={{
                                        __html: heading || "",
                                    }}
                                    size={headingSize}
                                />
                            )}
                            {(isInEditMode ||
                                (!introductionHtml && introduction)) && (
                                <Introduction
                                    {...addEditAttributes(
                                        `${editAttributePrefix}Introduction`,
                                    )}
                                    dangerouslySetInnerHTML={{
                                        __html: introduction || "",
                                    }}
                                />
                            )}
                            {(isInEditMode || introductionHtml) && (
                                <IntroductionHtml
                                    editPropertyName={"IntroductionHtml"}
                                    content={introductionHtml}
                                    renderElement={<BodyL />}
                                />
                            )}
                            {(isInEditMode || hasColumn) && (
                                <Col>
                                    {(isInEditMode || hasCTA) && (
                                        <CallToActions
                                            {...addEditAttributes(
                                                `${editAttributePrefix}CallToActionBlocks`,
                                            )}
                                        >
                                            {ctas?.map((cta, index) => {
                                                return (
                                                    <CallToAction
                                                        key={`CallToAction${index}`}
                                                        contentTheme={
                                                            contentTheme
                                                        }
                                                        identifier={
                                                            cta.identifier
                                                        }
                                                        heading={cta.heading}
                                                        text={cta.text}
                                                        icon={cta.icon}
                                                        url={cta.url}
                                                    />
                                                );
                                            })}
                                        </CallToActions>
                                    )}
                                    {(isInEditMode || link) && (
                                        <StyledButton
                                            key={`Button`}
                                            actionTheme={actionTheme}
                                            size={"large"}
                                            {...link}
                                        />
                                    )}
                                    {form?.formElements && (
                                        <StyledForm
                                            id={form.formId}
                                            items={form.formElements || []}
                                            areaName="FormElements"
                                            action={form.postUrl}
                                            spamTrapAction={form.spamTrapUrl}
                                            identifier={form.identifier}
                                            actionTheme={form.actionTheme}
                                            gtmFormType={form.gtmFormType}
                                            l18n={form.l18n}
                                            size={form.size}
                                        />
                                    )}
                                    {logo && (
                                        <Logo
                                            ref={heroImage}
                                            onLoad={onLogoLoad}
                                            src={logo.url}
                                            alt={logo.alt}
                                        />
                                    )}
                                </Col>
                            )}
                            {showArrowdown && (
                                <ReadMore>
                                    <ReadMoreButton
                                        onClick={handleClick}
                                        onKeyDown={handleKeyPress}
                                        title={l18n?.readMore}
                                        icon={`arrowDown${iconSize}`}
                                        a11y={true}
                                        actionTheme="white"
                                        iconOnly={true}
                                    >
                                        {l18n?.readMore}
                                    </ReadMoreButton>
                                </ReadMore>
                            )}
                        </>
                    )}
                </Container>
            </Section>
        </ThemeProvider>
    );
};

export default React.memo(Hero);
