import loadable, { DefaultComponent } from "@loadable/component";
import React, { ReactElement } from "react";

import WatermarkProps from "./WatermarkProps";

const Watermark = loadable(
    (): Promise<DefaultComponent<WatermarkProps>> =>
        import(/* webpackChunkName: "Watermark" */ "./Watermark"),
);
const WatermarkLoader = (props: WatermarkProps): ReactElement => (
    <Watermark {...props} />
);
export default WatermarkLoader;
